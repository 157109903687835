import React from "react";

import Layout from "../components/Layout";

export default function Datenschutz() {
  return (
    <Layout pageTitle={`Datenschutz`}>
      <div className="datenschutz site-content_inner">
        <h2>Datenschutz</h2>

        <p>
          Diese Datenschutz&shy;erklärung klärt Sie über die Art, den Umfang und
          Zweck der Verarbeitung von personen&shy;bezogenen Daten (nachfolgend
          kurz „Daten“) innerhalb unseres Online&shy;ange&shy;botes und der mit
          ihm ver&shy;bunde&shy;nen Web&shy;seiten, Funktionen und Inhalte sowie
          externen Online&shy;präsenzen, wie z.B. unser Social Media Profile auf
          (nach&shy;folgend gemeinsam bezeichnet als „Online&shy;angebot“).
        </p>
        <p>
          Im Hinblick auf die ver&shy;wendeten Begriff&shy;lich&shy;keiten, wie
          z.B. „Verarbeitung“ oder „Verant&shy;wortlicher“ verweisen wir auf die
          Defini&shy;tionen im Art. 4 der
          Daten&shy;schutz&shy;grund&shy;ver&shy;ordnung (DSGVO).
        </p>
        <p>
          Sie ist die bei der Stadt verwendete Erklärung und unterliegt
          keinerlei Lizenz&shy;pflicht.
        </p>

        <h3>Datenschutzerklärung nach der DSGVO</h3>

        <h4>I. Name und Anschrift des Verant&shy;wortlichen</h4>

        <p>
          Verantwortlicher gemäß EU-DSGVO und anderer nationaler
          Datenschutz&shy;gesetze (DSG NRW neu und BDSG neu):
        </p>

        <div className="impressum-box">
          <p>
            <span>
              Gesellschaft der Freunde und Förderer des Städtischen
              Luisen-Gymnasiums zu Düsseldorf e.V.
            </span>
            <span>
              Eingetragen in das Vereinsregister beim Amtsgericht Düsseldorf VR
              4109
            </span>
          </p>
          <p>
            <span>
              Im Sekretariat des Städtischen Luisen-Gymnasiums zu Düsseldorf
            </span>
            <span>Bastionstr. 24</span>
            <span>40213 Düsseldorf</span>
          </p>
          <p>
            <span>Inês Dabrowski (1. Vorsitzende)</span>
            <span>foerderverein@luisen-gymnasium.de</span>
          </p>
        </div>

        <h4>II. Allgemeines zur Daten&shy;ver&shy;arbeitung</h4>

        <h5>1. Umfang der Verar&shy;beitung personen&shy;bezogener Daten</h5>

        <p>
          Wir verarbeiten personen&shy;bezogene Daten unserer Nutzer
          grundsätzlich nur, soweit dies zur Bereit&shy;stellung einer
          funktions&shy;fähigen Website sowie unserer Inhalte und Leistungen
          erforderlich ist. Die Verarbeitung personen&shy;bezogener Daten
          unserer Nutzer erfolgt regelmäßig nur nach Ein&shy;willigung des
          Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige
          Einholung einer Einwilligung aus tatsäch&shy;lichen Gründen nicht
          möglich ist und die Verarbeitung der Daten durch gesetzliche
          Vorschriften gestattet ist.
        </p>

        <h5>
          2. Rechtsgrundlage für die Verar&shy;beitung personen&shy;bezogener
          Daten
        </h5>

        <p>
          Soweit wir für Verarbeitungs&shy;vorgänge personen&shy;bezogener Daten
          eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
          lit. a EU-Datenschutz&shy;grundverordnung (DSGVO) als
          Rechts&shy;grundlage. Bei der Verarbeitung von personen&shy;bezogenen
          Daten, die zur Erfüllung eines Vertrages, dessen Vertrags&shy;partei
          die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit.
          b DSGVO als Rechts&shy;grundlage. Dies gilt auch für
          Verarbeitungs&shy;vorgänge, die zur Durchführung vorver&shy;traglicher
          Maßnahmen erforderlich sind.
        </p>
        <p>
          Soweit eine Verarbeitung personen&shy;bezogener Daten zur Erfüllung
          einer rechtlichen Verpflichtung erforderlich ist, der unser
          Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
          Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der
          betroffenen Person oder einer anderen natürlichen Person eine
          Verarbeitung personen&shy;bezogener Daten erforderlich machen, dient
          Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
        </p>
        <p>
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
          Unternehmens oder eines Dritten erforderlich und überwiegen die
          Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
          erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
          Rechtsgrundlage für die Verarbeitung.
        </p>

        <h5>3. Datenlöschung und Speicherdauer</h5>

        <p>
          Die personen&shy;bezogenen Daten der betroffenen Person werden
          gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt.
          Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den
          euro&shy;päischen oder nationalen Gesetz&shy;geber in
          unions&shy;rechtlichen Ver&shy;ordnungen, Gesetzen oder sonstigen
          Vor&shy;schriften, denen der Verant&shy;wortliche unterliegt,
          vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch
          dann, wenn eine durch die genannten Normen vorge&shy;schriebene
          Speicherfrist abläuft, es sei denn, dass eine Erforder&shy;lichkeit
          zur weiteren Speicherung der Daten für einen Vertragsabschluss oder
          eine Vertrags&shy;erfüllung besteht.
        </p>

        <h4>III. Bereitstellung der Website und Erstellung von Logfiles</h4>

        <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>

        <p>
          Bei jedem Aufruf unserer Internet&shy;seite erfasst unser System
          automa&shy;tisiert Daten und Informa&shy;tionen vom
          Computer&shy;system des aufrufenden Rechners.
        </p>
        <p>Folgende Daten werden hierbei erhoben:</p>

        <ul>
          <li>Name und Adresse, sofern eingegeben</li>
          <li>Kontaktdaten, sofern eingegeben</li>
          <li>Inhalte, sofern eingegeben</li>
          <li>Metadaten (wie Datum und Uhrzeit des Zugriffs)</li>
          <li>IP-Adresse des Nutzers</li>
        </ul>

        <p>
          Die Daten werden ebenfalls in den Logfiles unseres Systems
          gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
          personen&shy;bezogenen Daten des Nutzers findet nicht statt.
        </p>

        <h5>2. Rechtsgrundlage für die Daten&shy;ver&shy;arbeitung</h5>

        <p>
          Rechtsgrundlage für die vorüber&shy;gehende Speicherung der Daten und
          der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
        </p>

        <h5>3. Zweck der Datenverarbeitung</h5>

        <p>
          Die vorüber&shy;gehende Speicherung der IP-Adresse durch das System
          ist notwendig, um eine Auslieferung der Web&shy;site an den Rechner
          des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers
          für die Dauer der Sitzung gespeichert bleiben. Die Speicherung anderer
          Daten dient der Beant&shy;wortung von Kontakt&shy;anfragen oder
          Kommunikation mit dem Nutzer. Eine Auswer&shy;tung der Daten zu
          anderen Zwecken wie z. B Marketing&shy;zwecken findet in diesem
          Zusam&shy;menhang nicht statt.
        </p>
        <p>
          Unsere in Anspruch genommenen Hosting-Leistungen dienen um folgende
          Leistungen zur Verfügung stellen zu können:
        </p>

        <ul>
          <li>Infrastrukturdienst&shy;leistungen</li>
          <li>Plattformdienst&shy;leistungen</li>
          <li>Rechenkapazität</li>
          <li>Speicherplatz</li>
          <li>Datenbankdienste</li>
          <li>E-Mail-Versand</li>
          <li>Sicherheitsleistungen</li>
          <li>technische Wartungs&shy;leistungen</li>
        </ul>

        <p>
          Alle genannten Leistungen setzen wir zum Betrieb dieses
          Online&shy;angebotes ein. Hier findet eine Verarbeitung statt von
          Bestands&shy;daten, Kontakt&shy;daten, Inhalts&shy;daten,
          Vertrags&shy;daten, Nutzungs&shy;daten, Meta- und
          Kommunikations&shy;daten von Kunden, Interessenten und Besuchern
          dieses Online&shy;angebotes auf Grundlage unserer berechtigten
          Interessen an einer effizienten und sicheren Zurverfügung&shy;stellung
          dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28
          DSGVO (Abschluss Auftragsverarbeitungsvertrag).
        </p>

        <h5>4. Dauer der Speicherung</h5>

        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
          der Daten zur Bereit&shy;stellung der Website ist dies der Fall, wenn
          die jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten
          in Logfiles ist dies nach spätestens 30 Tagen der Fall. Eine
          darüber&shy;hinaus&shy;gehende Speicherung ist möglich. In diesem Fall
          werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass
          eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
        </p>

        <h5>5. Widerspruchs- und Beseitigungs&shy;möglichkeit</h5>

        <p>
          Die Erfassung der Daten zur Bereit&shy;stellung der Website und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internet&shy;seite zwingend erforderlich. Es besteht folglich seitens
          des Nutzers keine Wider&shy;spruchs&shy;möglichkeit.
        </p>

        <h4>IV. Verwendung von Cookies</h4>

        <p>Unsere Website verwendet keine Cookies.</p>

        <h4>V. Rechte der betroffenen Person</h4>

        <p>
          Werden personen&shy;bezogene Daten von Ihnen verarbeitet, sind Sie
          Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
          dem Verant&shy;wortlichen zu:
        </p>

        <h5>1. Auskunftsrecht</h5>

        <p>
          Sie können von dem Verant&shy;wortlichen eine Bestätigung darüber
          verlangen, ob personen&shy;bezogene Daten, die Sie betreffen, von uns
          verarbeitet werden.
        </p>
        <p>
          Liegt eine solche Verarbeitung vor, können Sie von dem
          Verantwortlichen über folgende Informationen Auskunft verlangen:
        </p>

        <ol>
          <li>
            die Zwecke, zu denen die personen&shy;bezogenen Daten verarbeitet
            werden
          </li>
          <li>
            die Kategorien von personen&shy;bezogenen Daten, welche verarbeitet
            werden
          </li>
          <li>
            die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
            die Sie betreffenden personen&shy;bezogenen Daten offengelegt wurden
            oder noch offengelegt werden
          </li>
          <li>
            die geplante Dauer der Speicherung der Sie betreffenden
            personenb&shy;ezogenen Daten oder, falls konkrete Angaben hierzu
            nicht möglich sind, Kriterien für die Festlegung der Speicherdauer
          </li>
          <li>
            das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
            betreffenden personen&shy;bezogenen Daten, eines Rechts auf
            Einschränkung der Verar&shy;beitung durch den Verantwortlichen oder
            eines Widerspruchsrechts gegen diese Verarbeitung
          </li>
          <li>
            das Bestehen eines Beschwerde&shy;rechts bei einer Aufsichtsbehörde
          </li>
          <li>
            alle verfügbaren Informationen über die Herkunft der Daten, wenn die
            personen&shy;bezogenen Daten nicht bei der betroffenen Person
            erhoben werden
          </li>
          <li>
            das Bestehen einer automatisierten Entscheidungs&shy;findung
            einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
            zumindest in diesen Fällen – aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person.
          </li>
        </ol>

        <p>
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
          betreffenden personen&shy;bezogenen Daten in ein Drittland oder an
          eine internationale Organisation übermittelt werden. In diesem
          Zusammen&shy;hang können Sie verlangen, über die geeigneten Garantien
          gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet
          zu werden.
        </p>

        <h5>2. Recht auf Berichtigung</h5>

        <p>
          Sie haben ein Recht auf Berichtigung und/oder Vervoll&shy;ständigung
          gegenüber dem Verant&shy;wortlichen, sofern die verarbeiteten
          personen&shy;bezogenen Daten, die Sie betreffen, unrichtig oder
          unvoll&shy;ständig sind. Der Verantwortliche hat die Berichtigung
          unverzüglich vorzunehmen.
        </p>

        <h5>3. Recht auf Einschränkung der Verarbeitung</h5>

        <p>
          Unter den folgenden Voraussetzungen können Sie die Ein&shy;schränkung
          der Verarbeitung der Sie betreffenden personen&shy;bezogenen Daten
          verlangen:
        </p>

        <ol>
          <li>
            wenn Sie die Richtig&shy;keit der Sie betreffenden
            personen&shy;bezogenen für eine Dauer bestreiten, die es dem
            Verant&shy;wortlichen ermöglicht, die Richtigkeit der
            personen&shy;bezogenen Daten zu überprüfen
          </li>
          <li>
            die Verar&shy;beitung unrechtmäßig ist und Sie die Löschung der
            personen&shy;bezogenen Daten ablehnen und stattdessen die
            Einschränkung der Nutzung der personen&shy;bezogenen Daten verlangen
          </li>
          <li>
            der Verantwortliche die personen&shy;bezogenen Daten für die Zwecke
            der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
            Geltend&shy;machung, Ausübung oder Verteidigung von
            Rechts&shy;ansprüchen benötigen, oder
          </li>
          <li>
            wenn Sie Widerspruch gegen die Ver&shy;arbeitung gemäß Art. 21 Abs.
            1 DSGVO eingelegt haben und noch nicht feststeht, ob die
            berechtigten Gründe des Verant&shy;wortlichen gegenüber Ihren
            Gründen überwiegen.
          </li>
        </ol>

        <p>
          Wurde die Verarbei&shy;tung der Sie betreffenden
          personen&shy;bezogenen Daten eingeschränkt, dürfen diese Daten – von
          ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
          Geltend&shy;machung, Ausübung oder Verteidigung von
          Rechts&shy;ansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Union oder eines Mitgliedstaats
          verarbeitet werden.
        </p>
        <p>
          Wurde die Einschränkung der Verarbeitung nach den o.g.
          Voraus&shy;setzungen eingeschränkt, werden Sie von dem
          Verant&shy;wortlichen unterrichtet bevor die Einschränkung aufgehoben
          wird.
        </p>

        <h5>4. Recht auf Löschung</h5>

        <h6>a) Löschungspflicht</h6>

        <p>
          Sie können von dem Verant&shy;wortlichen verlangen, dass die Sie
          betreffenden personen&shy;bezogenen Daten unver&shy;züglich gelöscht
          werden, und der Verantwortliche ist verpflichtet, diese Daten
          unver&shy;züglich zu löschen, sofern einer der folgenden Gründe
          zutrifft:
        </p>

        <ol>
          <li>
            Die Sie betreffenden personen&shy;bezogenen Daten sind für die
            Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
            wurden, nicht mehr notwendig.
          </li>
          <li>
            Sie widerrufen Ihre Einwilligung, auf die sich die Verar&shy;beitung
            gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte,
            und es fehlt an einer ander&shy;weitigen Rechts&shy;grundlage für
            die Verarbeitung.
          </li>
          <li>
            Sie legen gem. Art. 21 Abs. 1 DSGVO Wider&shy;spruch gegen die
            Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
            für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
            Widerspruch gegen die Verarbeitung ein.
          </li>
          <li>
            Die Sie betreffenden personen&shy;bezogenen Daten wurden
            unrechtmäßig verarbeitet.
          </li>
          <li>
            Die Löschung der Sie betreffenden personen&shy;bezogenen Daten ist
            zur Erfüllung einer recht&shy;lichen Verpflichtung nach dem
            Unions&shy;recht oder dem Recht der Mitglied&shy;staaten
            erforderlich, dem der Verantwortliche unterliegt.
          </li>
          <li>
            Die Sie betreffenden personen&shy;bezogenen Daten wurden in Bezug
            auf angebotene Dienste der Informations&shy;gesellschaft gemäß Art.
            8 Abs. 1 DSGVO erhoben.
          </li>
        </ol>

        <h6>b) Information an Dritte</h6>

        <p>
          Hat der Verant&shy;wortliche die Sie betreffenden
          personen&shy;bezogenen Daten öffentlich gemacht und ist er gem. Art.
          17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter
          Berück&shy;sichtigung der verfügbaren Technologie und der
          Implementierungs&shy;kosten angemessene Maßnahmen, auch technischer
          Art, um für die Daten&shy;verarbeitung Verant&shy;wortliche, die die
          personen&shy;bezogenen Daten verarbeiten, darüber zu informieren, dass
          Sie als betroffene Person von ihnen die Löschung aller Links zu diesen
          personen&shy;bezogenen Daten oder von Kopien oder Replikationen dieser
          personen&shy;bezogenen Daten verlangt haben.
        </p>

        <h6>c) Ausnahmen</h6>

        <p>
          Das Recht auf Löschung besteht nicht, soweit die Verar&shy;beitung
          erforderlich ist
        </p>

        <ol>
          <li>
            zur Ausübung des Rechts auf freie Meinungs&shy;äußerung und
            Information
          </li>
          <li>
            zur Erfüllung einer rechtlichen Ver&shy;pflichtung, die die
            Ver&shy;arbeitung nach dem Recht der Union oder der
            Mitglied&shy;staaten, dem der Verantwortliche unterliegt, erfordert,
            oder zur Wahr&shy;nehmung einer Aufgabe, die im öffentlichen
            Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die
            dem Verantwortlichen übertragen wurde
          </li>
          <li>
            aus Gründen des öffent&shy;lichen Interesses im Bereich der
            öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art.
            9 Abs. 3 DSGVO
          </li>
          <li>
            für im öffentlichen Interesse liegende Archivzwecke,
            wissen&shy;schaftliche oder historische Forschungs&shy;zwecke oder
            für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
            Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
            Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beein&shy;trächtigt, oder
          </li>
          <li>
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </li>
        </ol>

        <h5>5. Recht auf Unterrichtung</h5>

        <p>
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschr&shy;änkung
          der Verarbeitung gegenüber dem Verantwort&shy;lichen geltend gemacht,
          ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
          personen&shy;bezogenen Daten offengelegt wurden, diese Berichtigung
          oder Löschung der Daten oder Einschränkung der Verarbei&shy;tung
          mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit
          einem unver&shy;hältnis&shy;mäßigen Aufwand verbunden.
        </p>
        <p>
          Ihnen steht gegenüber dem Verant&shy;wortlichen das Recht zu, über
          diese Empfänger unter&shy;richtet zu werden.
        </p>

        <h5>6. Recht auf Datenübertrag&shy;barkeit</h5>

        <p>
          Sie haben das Recht, die Sie betreffenden personen&shy;bezogenen
          Daten, die Sie dem Verantwortlichen bereit&shy;gestellt haben, in
          einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten. Außerdem haben Sie das Recht diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personen&shy;bezogenen Daten bereitgestellt wurden, zu übermitteln,
          sofern
        </p>

        <ol>
          <li>
            die Ver&shy;arbeitung auf einer Einwilli&shy;gung gem. Art. 6 Abs. 1
            lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag
            gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
          </li>
          <li>
            die Verarbeitung mithilfe automatisierter Ver&shy;fahren erfolgt.
          </li>
          <li>
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken,
            dass die Sie betreffen&shy;den personen&shy;bezogenen Daten direkt
            von einem Verantwort&shy;lichen einem anderen Verantwort&shy;lichen
            übermittelt werden, soweit dies technisch machbar ist. Freiheiten
            und Rechte anderer Personen dürfen hier&shy;durch nicht
            beeinträchtigt werden.
          </li>
        </ol>

        <p>
          Das Recht auf Datenübertrag&shy;barkeit gilt nicht für eine
          Verar&shy;beitung personen&shy;bezogener Daten, die für die
          Wahr&shy;nehmung einer Aufgabe erforderlich ist, die im
          öffent&shy;lichen Interesse liegt oder in Ausübung öffentlicher Gewalt
          erfolgt, die dem Verantwortlichen übertragen wurde.
        </p>

        <h6>Online-Formular „Beitrittserklärung“ (Netlify)</h6>

        <p>
          Wir verwenden die Dienste von Netlify Inc. (“Netlify”). Indem Sie uns
          personen&shy;bezogene Daten zur Verfügung stellen, erklären Sie sich
          frei&shy;willig mit der Erhebung, Verwendung und Offen&shy;legung
          solcher personen&shy;bezogenen Daten an Netlify einverstanden. Netlify
          gibt Ihre persönlichen Daten nur an Dritte weiter, sofern dies für die
          Bereit&shy;stellung eines bestimmten Dienstes erforderlich und im
          Einklang mit unseren Datenschutz&shy;bestimmungen ist.
        </p>

        <p>
          Weiterführende Infor&shy;mationen zu der Datenschutz&shy;richtlinie
          von “Netlify” finden Sie unter{" "}
          <a href="https://www.netlify.com/privacy/">
            https://www.netlify.com/privacy/
          </a>{" "}
          und{" "}
          <a href="https://www.netlify.com/gdpr/">
            https://www.netlify.com/gdpr/
          </a>
        </p>

        <h5>7. Widerspruchsrecht</h5>

        <p>
          Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
          Situation ergeben, jederzeit gegen die Ver&shy;arbeitung der Sie
          betreffen&shy;den personen&shy;bezogenen Daten, die aufgrund von Art.
          6 Abs. 1 lit. e oder f DSGVO erfolgt, Wider&shy;spruch einzulegen;
          dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
        </p>
        <p>
          Der Verant&shy;wortliche verarbeitet die Sie betreffenden
          personen&shy;bezogenen Daten nicht mehr, es sei denn, er kann
          zwingende schutz&shy;würdige Gründe für die Verarbeitung nachweisen,
          die Ihre Interessen, Rechte und Frei&shy;heiten über&shy;wiegen, oder
          die Verar&shy;beitung dient der Geltendm&shy;achung, Ausübung oder
          Verteidigung von Rechts&shy;ansprüchen.
        </p>
        <p>
          Werden die Sie betreffenden personen&shy;bezogenen Daten verarbeitet,
          um Direkt&shy;werbung zu betreiben, haben Sie das Recht, jederzeit
          Wider&shy;spruch gegen die Verarbei&shy;tung der Sie betreffenden
          personen&shy;bezogenen Daten zum Zwecke derartiger Werbung einzulegen;
          dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung
          in Verbindung steht.
        </p>
        <p>
          Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
          werden die Sie betreffenden personen&shy;bezogenen Daten nicht mehr
          für diese Zwecke verarbeitet.
        </p>
        <p>
          Sie haben die Möglichkeit, im Zusammen&shy;hang mit der Nutzung von
          Diensten der Informations&shy;gesellschaft – ungeachtet der Richtlinie
          2002/58/EG – Ihr Widerspruchsrecht mittels auto&shy;matisierter
          Verfahren auszuüben, bei denen technische Spezifikationen verwendet
          werden.
        </p>

        <h5>
          8. Recht auf Widerruf der datenschutz&shy;rechtlichen
          Einwilligungs&shy;erklärung
        </h5>

        <p>
          Sie haben das Recht, Ihre datenschutz&shy;rechtliche
          Einwilligungs&shy;erklärung jederzeit zu widerrufen. Durch den
          Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der
          Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
        </p>

        <h5>
          9. Automatisierte Entscheidung im Einzelfall einschließ&shy;lich
          Profiling
        </h5>

        <p>
          Sie haben das Recht, nicht einer ausschließlich auf einer
          automa&shy;tisierten Verar&shy;beitung – einschließlich Profiling –
          beruhenden Ent&shy;scheidung unterworfen zu werden, die Ihnen
          gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise
          erheblich beein&shy;trächtigt. Dies gilt nicht, wenn die Entscheidung
        </p>

        <ol>
          <li>
            für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen
            und dem Verantwort&shy;lichen erforderlich ist,
          </li>
          <li>
            aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
            denen der Verantwortliche unterliegt, zulässig ist und diese
            Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte
            und Freiheiten sowie Ihrer berech&shy;tigten Interessen enthalten
            oder
          </li>
          <li>mit Ihrer ausdrück&shy;lichen Einwilligung erfolgt.</li>
        </ol>

        <p>
          Allerdings dürfen diese Entschei&shy;dungen nicht auf besonderen
          Kategorien personen&shy;bezogener Daten nach Art. 9 Abs. 1 DSGVO
          beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und
          ange&shy;messene Maßnahmen zum Schutz der Rechte und Freiheiten sowie
          Ihrer berech&shy;tigten Interessen getroffen wurden.
        </p>

        <p>
          Hinsichtlich der in (1) und (3) genannten Fälle trifft der
          Verant&shy;wortliche ange&shy;messene Maßnahmen, um die Rechte und
          Frei&shy;heiten sowie Ihre berechtigten Interessen zu wahren, wozu
          min&shy;destens das Recht auf Erwirkung des Ein&shy;greifens einer
          Person seitens des Verant&shy;wortlichen, auf Darlegung des eigenen
          Stand&shy;punkts und auf Anfechtung der Ent&shy;scheidung gehört.
        </p>

        <h5>10. Recht auf Beschwerde bei einer Aufsichts&shy;behörde</h5>

        <p>
          Unbeschadet eines anderweitigen verwaltungs&shy;rechtlichen oder
          gericht&shy;lichen Rechts&shy;behelfs steht Ihnen das Recht auf
          Beschwerde bei einer Aufsichts&shy;behörde, insbesondere in dem
          Mitglied&shy;staat ihres Aufenthalts&shy;orts, ihres
          Arbeits&shy;platzes oder des Orts des mut&shy;maßlichen Verstoßes, zu,
          wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personen&shy;bezogenen Daten gegen die DSGVO verstößt.
        </p>
        <p>
          Die Aufsichts&shy;behörde, bei der die Beschwerde eingereicht wurde,
          unterrichtet den Beschwerde&shy;führer über den Stand und die
          Ergeb&shy;nisse der Beschwerde einschließ&shy;lich der
          Möglich&shy;keit eines gericht&shy;lichen Rechts&shy;behelfs nach Art.
          78 DSGVO
        </p>
      </div>
    </Layout>
  );
}
